.history-image-dummy {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
}

.numeric-component {
    .ui.button {
        margin: 0 !important;
        font-size: 0.7rem;
    }

    span {
        display: inline-block;
        width: 3rem;
        padding: 0 1rem;
        text-align: center;
    }
}

.checkmark-item {
    margin-left: 35px;
    padding-top: 0.6rem;

    &.first {
        padding-top: 1.2rem;
    }

    span:before {
        color: $green;
        font-size: 1.3rem;
        line-height: 0.5rem;
        padding-right: 0.8rem;
    }

    .bluekit-icon {
        padding-top: 2px;
        display: inline-block;
    }

    label {
        padding-left: 0px;
        vertical-align: top;
    }
}

#calculateVentilationArea {

    &.ui.grid {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &.ui.grid > .row {
        padding: 0;
    }

    .ui.divider {
        margin: 0.3rem 0rem;
    }

    label {
        font-size: 0.9rem;
    }

    .ui.disabled.input {
        opacity: 1;

        input {
            background: $darkblue;
            color: white;
            font-weight: 800;
        }
    }
}

.recRibbon {
    border-color: $darkgreen !important;
    background-color: $green !important;
    color: white !important;
}

.ui.input.rec, .ui.selection.dropdown.rec {
    input, div.text {
        font-weight: 800;
        color: $green !important;
    }
}

.error-message {
    position: absolute !important;
    left: 0;
}

.ui.accordion .title:not(.ui) {
    padding-top: 0;
}

.indentend-text {
    margin-left: 14px;
}

.number-interface-adapters {
    margin-top: 32px;


    &__description {
        font-size: 15px;
        font-weight: 500;
        color: #002d59;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.87);
    }
}
