@import '../shared/colors';

.layout-container {
    // originally in HomePage.scss (now ProjectExplorerPage.scss)
    .flex-container {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        flex-direction: row;
    }

    .flex-vertical-center {
        align-items: center;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .flex-grow-no {
        flex-grow: 0 !important;
    }

    // @TODO: Check if still used and remove
    .flex-horizontal-end {
        align-items: center;
    }
}

.layout-container > .wrapper {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: $brightgrey;

    > .header.menu {
        background: white;
        box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
        padding: 14px;

        .logo {
            padding: 5px;
        }

        .title {
            text-align: center;
        }

        a {
            font-size: 18px;
            text-align: center;
            display: block;
            font-weight: bold;
            padding: 3px 20px;
            line-height: 24px;

            &.active {
                background: #E1EDF8;
            }
        }

        .language-select,
        .currency-select {
            margin-left: 1em;
            z-index: 900; // .ui.dimmer is 1000 by default, stay behind that
        }

        .language-select {
            min-width: 10em !important;
        }

        .currency-select {
            min-width: 4em !important;
        }
    }

    .header-title {
        margin: 0 auto 30px auto;
        text-align: center;
    }

    > .site-content {
        padding: 30px;
        position: relative;

        > .contact-container {
            position: absolute;
            right: 0;
            top: 8px;

            .contact-nav {
                position: fixed;
                -webkit-transform: translate(-100%);
                transform: translate(-100%);

                display: flex;
                flex-direction: column;

                .item {
                    background-color: #002d59;
                    border-radius: 3px 0 0 3px;
                    margin-bottom: 2px;
                    transition: background .25s ease-in-out;
                    cursor: pointer;
                    color: #fff;
                    font-size: 20px;
                    width: 40px;
                    height: 40px;

                    padding-left: 5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background-color: #005e99;
                    }

                    .info {
                        position: absolute;
                        right: 0;

                        overflow: hidden;
                        transition: width .3s ease-in;

                        cursor: pointer;
                        user-select: none;

                        width: 40px;
                        height: 40px;
                        background-color: #005e99;
                        border-radius: 3px 0 0 3px;

                        padding-left: 10px;

                        white-space: nowrap;

                        z-index: -1;

                        display: flex;
                        align-items: center;

                        font-size: 16px;
                        font-weight: bold;

                        &.active {
                            width: 210px;
                        }
                    }
                }
            }
        }
    }
}

.layout-container > .footer {
    height: 5rem;
    padding: 2rem;
}
