
.ui.page.modals {

    overflow-y: scroll;

    .ui.modal {

        width: 50%;

        & > .content {
            background: $brightgrey;
        }

        .content-pane {
            .description {
                padding-left: 100px;
                padding-right: 140px;
            }
        }

        .ui.radio.checkbox {
            font-size: 1.5rem;
            font-weight: 700;
        }

        .ct-radio-label {
            padding-left: 30px;
        }
    }

    .ImageModal:not(\#9) {
        width: auto !important;
    }
}

@media (max-width: 667px) {
    .ui.modal {
        width: 100%;
    }
}

.ui.modal {
    .header {
        display: flex;
        flex-direction: row;

        .title {
            flex-grow: 1;
        }
        .close-button {
            cursor: pointer;
        }
    }
}
