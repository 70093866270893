@import '../colors';

.ui.input {
    input {
        border-radius: 2px !important;
        line-height: 2rem !important;
        border: 0.5px solid $lightgrey !important;
    }

    &.small {
        input {
            padding: 0.5em 0.8em;
        }
    }

    .label {
        line-height: 1.7rem !important;
    }
}

.ui.input {
    button.green {
        background-color: $green !important;

        &:hover {
            background-color: $darkgreen;
        }
    }
}

.ui.green.button {
    background-color: $green !important;
}

.ui.checkbox {
    label {
        color: $darkblue;
    }
}

.ui.basic.label {
    background-color: $darkblue;
    color: white;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before,
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background-color: $green !important;
}

.ui.input.error > input {
    background-color: $lightred !important;
    border-color: $midred !important;
    color: $red !important;
}

.numeric-component {
    .ui.button.numeric-button {
        font-size: 0.7rem;
        margin-right: 0;
    }

    span {
        display: inline-block;
        width: 3rem;
        padding: 0 1rem;
        text-align: center;
    }
}

.numeric-component .ui.button.numeric-button, .ui.radio.checkbox {
    margin-top: 0.4em !important;
}
