.ProjectExplorerPage:not(#\9) {
    .project-action-card {
        .description {
            padding: 20px;
        }

        .input {
            width: 100%;
        }
    }

    .message-account-notActivated {
        margin: 0 auto;
        height: 500px;
    }
}
