.ProjectExplorerPage:not(#\9) .ProjectTable:not(#\9) {
    tr.row-create > td {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .col-name-id > span {
        font-size: 0.8rem;
        opacity: 0.8;
    }
}

.ProjectExplorerPage--ProjectTable--DeleteProjectModal:not(#\9) {
    table.project-info > tbody > tr > td:first-child {
        font-weight: bold;
    }
}
