// proxima-nova
@font-face {
    font-family: 'Proxima Nova';
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/proxima-nova/proxima-nova-regular.ttf') format('truetype'),
         url('./fonts/proxima-nova/proxima-nova-regular.woff') format('woff2'),
         url('./fonts/proxima-nova/proxima-nova-regular.woff2') format('woff'),
         url('./fonts/proxima-nova/proxima-nova-regular.eot') format('eot');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: bold;
    font-style: normal;
    src: url('./fonts/proxima-nova/proxima-nova-bold.ttf') format('truetype'),
         url('./fonts/proxima-nova/proxima-nova-bold.woff') format('woff2'),
         url('./fonts/proxima-nova/proxima-nova-bold.woff2') format('woff'),
         url('./fonts/proxima-nova/proxima-nova-bold.eot') format('eot');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: normal;
    src: url('./fonts/proxima-nova/proxima-nova-light.ttf') format('truetype'),
         url('./fonts/proxima-nova/proxima-nova-light.woff') format('woff2'),
         url('./fonts/proxima-nova/proxima-nova-light.woff2') format('woff'),
         url('./fonts/proxima-nova/proxima-nova-light.eot') format('eot');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 200;
    font-style: normal;
    src: url('./fonts/proxima-nova/proxima-nova-thin-t.ttf') format('truetype'),
         url('./fonts/proxima-nova/proxima-nova-thin-t.woff') format('woff2'),
         url('./fonts/proxima-nova/proxima-nova-thin-t.woff2') format('woff'),
         url('./fonts/proxima-nova/proxima-nova-thin-t.eot') format('eot');
}
