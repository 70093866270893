.ProductsTable:not(\#9) {
    tr.row-group > td {
        &.image {
            height: 48px + 1px;
            text-align: center; // Center this column

            .thumbnail {
                padding: 0 !important;
                background-color: transparent !important;
            }
        }

        &.info > div {
            // Space out various product group info (mounting, info URL)
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    tr.row-group-product > td {
        height: 3em;
        vertical-align: middle;
    }

    tr.row-group-more > td {
        text-align: center;
    }

    tr.row-group-product,
    tr.row-group-bracket,
    tr.row-group-more {
        > td {
            border-top: none; // Only have borders around product groups
            > a {
                cursor: pointer;
            }
        }
    }

    tr > th,
    tr > td {
        &.quantity {
            min-width: 10em;
            text-align: center;
        }
        &.name {
            width: auto;
        }
        &.dhNo {
            min-width: 8em;
            text-align: center;
        }
        &.ventilationArea {
            min-width: 10em;
            text-align: right;
        }
        &.BxH {
            min-width: 8em;
            text-align: center;
        }
        &.recess {
            min-width: 8em;
            text-align: center;
        }
        &.recommendation {
            min-width: 8em;
        }
    }
}
