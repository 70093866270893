.ui.button {
    font-size: 1.1rem;
}

.ui.primary.button {
    border: 2px solid $green;
    background: $green;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    color: white;

    &:active, &:focus {
        background-color: $green;
        border-color: $green;
    }

    &:hover, &:active, &:focus {
        background-color: $darkgreen;
        border-color: $darkgreen;
    }
}

.ui.secondary.button {
    border: 2px solid $darkblue;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    color: $darkblue;
    background: white;

    &:active, &:focus {
        background: white;
        color: $darkblue;
    }

    &:hover {
        background-color: $darkblue;
        color: white;
    }
}

.info-button {
    padding: 0em 0.7em 0em !important;
    background: none !important;

    &:hover {
        background: none !important;
    }
}

.info-button-small {
    padding: 0em 0em 0em 0.7em !important;
    margin: 0 !important;
}

.image-button {
    padding: 0em 0.7em 0em !important;
    font-size: 20px !important;
    background: none !important;

    &:hover {
        background: none !important;
    }
}

.image-button-small {
    height: 20px;
}

.image-button-dropdown {
    padding-top: 19px !important;
}

.btn-group {
    display: flex;
    align-items: center;
    flex-flow: row-reverse wrap;

    &.centered {
        justify-content: center;
    }

    .button {
        margin: 0.5rem;
    }
}
