@import '../../../shared/colors';

.CalculationTabs:not(#\9) {
    .tabular {
        border: 0 !important;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;

        &.ui.attached.menu {
            background-color: white;
            border-radius: 0.75rem;
        }

        .item:hover {
            border-bottom: solid $darkblue 3px !important;

            .menu-item-header {
                color: $darkblue;

                span.label {
                background-color: $darkblue;
                color: white;
                }
            }
        }

        .item {
            padding: 0 !important;
            border: 0 !important;
            border-bottom: solid $lightgrey 3px !important;

            .circular.label {
                background-color: $blue !important;
                color: white;
            }

            &.active {
                border-bottom: solid $darkblue 3px !important;
            }

            .menu-item-header {
                padding: 18px 10px 18px 10px;
                color: $blue;
                font-weight: 800;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    margin-right: 10px;
                    font-weight: 800;
                    overflow-x: hidden;
                    overflow-y: visible;
                    text-overflow: ellipsis;
                    display: block;

                    &.title {
                        line-height: 24px;
                    }
                }

                &:hover {
                    cursor: default;
                }

                &.active {
                    background: $darkblue;
                    color: white;

                    .circular.label {
                        background-color: white !important;
                        color: $darkblue !important;
                    }
                }

                &.disabled {
                    color: grey;

                    .circular.label {
                        background-color: grey !important;
                    }
                }
            }
        }
    }

    .ui.segment.bottom.attached:last-child {
        margin-bottom: 1rem;
    }

    .ui.tab {
        padding: 0 !important;
        border: 0 !important;
        background-color: $brightgrey;
    }
}
