/* Lato from Google Fonts via google-webfonts-helper */
/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/lato/lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/lato/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/lato/lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/lato/lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/lato/lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/lato/lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/lato/lato-v23-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/lato/lato-v23-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/lato/lato-v23-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/lato/lato-v23-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/lato/lato-v23-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/lato/lato-v23-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/lato/lato-v23-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/lato/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/lato/lato-v23-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/lato/lato-v23-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./fonts/lato/lato-v23-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/lato/lato-v23-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/lato/lato-v23-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/lato/lato-v23-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/lato/lato-v23-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/lato/lato-v23-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/lato/lato-v23-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/lato/lato-v23-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}
