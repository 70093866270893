$darkblue: #002d59;
$midblue: #005e99;
$blue: #0092d3;

$grey: #979797;
$brightgrey: #f7f7f7;
$lightgrey: #d7d7d7;
$fontgrey: #1B1C1D;

$green: #69a60f;
$darkgreen: #5b9506;

$lightred: #fff6f6;
$midred: #e0b4b4;
$red: #9f3a38;

.darkBlue {
    color: $darkblue;
}

.midBlue {
    color: $midblue;
}

.lightBlue {
    color: $blue;
}
