.CalculationPage:not(#\9) {
    .calculation-container {
        margin: 0 auto;
        min-width: 90%;
    }

    .non-standard-component-input-row {
        div.ui.input {
            width: 100%;
        }
    }

    .file-upload-area {
        margin-top: 10px;
    }

    .file-link:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .ventilation-title {
        text-align: center;
        margin-top: 10px;
    }

    .price-col {
        min-width: 130px;
    }

    .debug-section {
        margin-top: 25px;
    }

    .debug-logistic-costs {
        td.bold {
            font-weight: bold;
        }
    }
}
