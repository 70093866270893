.Spinner {
    min-height: 100px;
    border: 0 !important;
    box-shadow: none !important;

    > .ui.dimmer {
        z-index: 0;
    }

    &.segment {
        height: 600px;
        border: 0 !important;
        box-shadow: none !important;

        &.small {
            height: unset;
        }
    }
}
