.file-uploader {
    font-size: 16px;
}

.file-uploader--dragging {
    .file-uploader__contents {
        background-color: #EEE;
    }
}

.file-uploader__contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #CCC;
    border-radius: 8px;
    width: 300px;
    height: 150px;
    padding: 10px;
    background: white;
    margin-bottom: 10px;
    //background-color: white;
    //padding: 20px;
}

.file-uploader__file-name {
    font-weight: 700;
}

.file-uploader__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}
