/*Example: <span className="bluekit-icon icon-checkmark-sharp"></span>*/
.bluekit-icon {
    font-family: 'bluekit';
}

.icon-arrow-down:before {
    content: "\E900";
}

.icon-arrow-left:before {
    content: "\E901";
}

.icon-arrow-tight:before {
    content: "\E902";
}

.icon-arrow-up:before {
    content: "\E903";
}

.icon-chevron-down:before {
    content: "\E904";
}

.icon-chevron-left:before {
    content: "\E905";
}

.icon-chevron-right:before {
    content: "\E906";
}

.icon-chevron-up:before {
    content: "\E907";
}

.icon-checkmark:before {
    content: "\E908";
}

.icon-big-chevron-down:before {
    content: "\E909";
}

.icon-big-chevron-left:before {
    content: "\E90A";
}

.icon-big-chevron-right:before {
    content: "\E90B";
}

.icon-big-chevron-up:before {
    content: "\E90C";
}

.icon-cross:before {
    content: "\E90D";
}

.icon-gear:before {
    content: "\E90E";
}

.icon-letter:before {
    content: "\E90F";
}

.icon-people:before {
    content: "\E910";
}

.icon-home:before {
    content: "\E911";
}

.icon-leaf:before {
    content: "\E912";
}

.icon-cross-arrows:before {
    content: "\E913";
}

.icon-menu:before {
    content: "\E914";
}

.icon-minus:before {
    content: "\E915";
}

.icon-cashbag:before {
    content: "\E916";
}

.icon-phone:before {
    content: "\E917";
}

.icon-plus:before {
    content: "\E918";
}

.icon-search:before {
    content: "\E919";
}

.icon-sun:before {
    content: "\E91A";
}

.icon-notebook-gear:before {
    content: "\E91B";
}

.icon-truck-gear:before {
    content: "\E91C";
}

.icon-wrench-gear:before {
    content: "\E91D";
}

.icon-people-gear:before {
    content: "\E91E";
}

.icon-checkmark-award:before {
    content: "\E91F";
}

.icon-battery-load:before {
    content: "\E920";
}

.icon-tall-buildings:before {
    content: "\E921";
}

.icon-shaft-1:before {
    content: "\E922";
}

.icon-shaft-2:before {
    content: "\E923";
}

.icon-shaft-3:before {
    content: "\E924";
}

.icon-shaft-4:before {
    content: "\E925";
}

.icon-construction:before {
    content: "\E926";
}

.icon-inductor:before {
    content: "\E927";
}

.icon-checkmark-sharp:before {
    content: "\E928";
}

.icon-download:before {
    content: "\E929";
}
