@import '../colors';

.ui.form .field, .ui.form .inline.fields {
    > label {
        font-weight: normal;
        color: $fontgrey;
        font-size: 1em;
    }
}

.ui.form .field {
    align-self: flex-end;
}

.ui.selection.active.dropdown .menu {
    padding: 10px;
}

.ui.form .fields, .ui.form .inline.fields {
    margin-bottom: 1.5em;
}

.flex-5 {
    flex-basis: 5%;
}

.flex-50 {
    flex-basis: 50%;
}

label {
    &.position-end {
        margin-left: 20px !important;
    }
}

.cable-modal-field-pre-label {
    width: 100px;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.montage-error {
    padding-left: 26px;
    color: #9f3a38;
    margin-bottom: 1.5em !important;
}
