.ui.table:not(.basic) > thead th {
    background-color: $midblue;
    color: white;
    border-left-color: rgba(255, 255, 255, 0.5) !important;
}

.ui.table tr {
    &.boldRow td, &.boldRow th {
        font-weight: bold;
    }

    &.darkBlueRow td, &.darkBlueRow th {
        background-color: $darkblue;
        color: white;

        .icon {
            color: white;
        }
    }

    &.midBlueRow td, &.midBlueRow th {
        background-color: $midblue;
        color: white;

        .icon {
            color: white;
        }
    }

    &.lightBlueRow td, &.lightBlueRow th {
        background-color: $blue;
    }
}

.ui.celled.table tr td, .ui.accordion {
    .ui.label.accordionLabel {
        background: none;
        color: $blue;
        text-align: right;
        width: 100%;

        .icon.chevron {
            display: inline;
            margin: 0 0 0 0.75em;
            vertical-align: text-bottom;
        }
    }
}

@media only screen and (max-width: 767px) {
    .ui.table:not(.unstackable) thead {
        background-color: $midblue;
    }
    .ui.table:not(.unstackable) tr {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.cost-table {
    .row-even {
        background-color: rgba(0, 0, 50, .02);
    }

    .table-row-item-accordion td {
        &.no-border {
            border-left: none !important;
        }

        border-top: none !important;
    }

    .quantity {
        width: 6%;
    }

    .dhNo {
        width: 15%;
    }
}

.non-standard-component-table {
    .non-standard-component-table-row {
        td {
            padding-left: 25px !important;
        }
    }

    .price {
        min-width: 120px !important;
    }
}

.ui.table td.no-padding {
    padding: 0 !important;
}

.table-row-accordion .parts-table {
    margin-top: 0;
    border: none;
    margin-bottom: 14px !important;

    background-color: transparent !important;

    tr {
        background-color: transparent !important;
    }

    td {
        border: none !important;
    }

    th, td {
        padding: 6px 11px;
    }

    .quantity {
        width: 10%;
    }

    .dhNo {
        width: 20%;
    }
}

.table-row-accordion .title {
    padding: 14px 11px;
}

.table-row-accordion td.price-cell {
    min-width: 9em;
    padding: 14px;
}

.ventilation-table .quantity {
    width: 130px;
}
