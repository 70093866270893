.wrapper:has(.simulation-container) {
    background: white !important;

    > .site-content {
        padding: 0 0 !important;
        padding-top: 16px !important;
    }
}

.simulation-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    > iframe {
        width: 100%;
        height: calc(100vh - 145px);
        border-top: 1px solid #eee;
    }
}
