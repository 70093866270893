@import '../colors';

.cards-container {
    margin: 0 auto !important;
}

.content-pane {
    background-color: white;
    padding: 30px;
    border-radius: 0.75rem;

    .ui.toggle.checkbox {
        float: right;
    }

    h2 {
        margin-top: 0;
    }
}
