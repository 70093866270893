@import 'shared/colors';
@import 'shared/fonts';
@import 'shared/headings';

.layout-v1:not(#\9) {
    @import 'shared/components/buttons';
    @import 'shared/components/minors';
    @import 'shared/components/cards';
    @import 'shared/components/form';
    @import 'shared/components/input';
    @import 'shared/components/dropdown';
    @import 'shared/components/bluekiticons';
    @import 'shared/components/tables';
}

@import 'shared/components/modals';

@import 'shared/components/file-uploader/file-uploader';

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
}

@media (min-width: 768px) {
    /* On small screens, convert the nav menu to a vertical sidebar */
}

body {
    background-color: $darkblue;
}

* {
    font-family: 'Proxima Nova', 'Open Sans', sans-serif;
}

*[role='button']:focus {
    outline: none;
}

*:focus {
    outline: none;
}

.toast-top-center {
    top: 5px;
}

pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.height-50 {
    height: 50%;
}


.ant-notification {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    margin: 0;
    pointer-events: none;
}

.ant-notification-notice {
    width: 384px;
    margin-left: auto;
    margin-right: auto;
    pointer-events: auto;

    &.clickable {
        cursor: pointer;
    }

    &.success {
        background-color: $green !important;
    }

    &.error {
        background-color: #9f3a38 !important;
    }
}

.ant-notification-notice-message {
    color: white;
    margin-bottom: 0;
}

.ant-notification-notice-close {
    color: white;
}

.ant-notification-notice-close:hover {
    color: white;
}

@keyframes NotificationFadeIn {
    0% {
        bottom: 50px;
        opacity: 0;
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes NotificationLeftFadeIn {
    0% {
        top: 50px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}


.input-for-currency {
    .ant-input {
        text-align: right;
    }
}

.input-for-number {
    input {
        text-align: right;
    }
}

.input-for-integer {
    label {
        display: block;
        margin-bottom: 4px;
    }
    input {
        text-align: right;
    }
}

.form-group {
    margin-bottom: 1.5em;
}

.ant-input-number-handler-wrap {
    display: none !important;
}


.ant-input {
    min-width: 100px;
}

.info-centered {
    text-align: center;
}

.d-inline-block {
    display: inline-block;
}

.blue-theme .ant-input-group-addon {
    background-color: $darkblue;
    color: white;
    font-weight: bold;
    border-color: rgba(34,36,38,.15);
}
